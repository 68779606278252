import { FORBIDDEN } from 'http-status-codes';

import { CountryId } from 'lux/models/countryId';
import {
    employerConstructorModifyWidget,
    employerConstructorSetSaved,
    setConstructorStatus,
    Status,
} from 'lux/models/employerConstructor';
import { openConnectReviewsModal } from 'lux/models/employerReviews/employerReviewsModal';
import validate from 'src/components/EmployerConstructor/validate';
import {
    employerConstructorEditForbiddenError,
    employerConstructorSaveTemplateError,
    employerConstructorSaveTemplateOk,
} from 'src/components/Notifications/EmployerConstructor';
import fetcher from 'src/utils/fetcher';

const sortWidgets = (widgets, sortIds) => {
    if (sortIds) {
        widgets.sort((widgetA, widgetB) => {
            const widgetAId = widgetA.widget?.id || widgetA.id;
            const widgetBId = widgetB.widget?.id || widgetB.id;
            return sortIds.indexOf(widgetAId) - sortIds.indexOf(widgetBId);
        });
    }

    return widgets;
};

const getOnlyValidWidgets = (widgets, errorWidgets) => {
    const errorWidgetsIds = errorWidgets.map(({ widget }) => widget.id);
    return widgets.filter((widget) => !errorWidgetsIds.includes(widget.id));
};

const saveConstructorTemplate = (addNotification) => (dispatch, getState) => {
    const { employerConstructor, employerConstructorSettings, employerAddresses } = getState();

    const errorWidgets = validate({
        ...employerConstructor,
        settings: employerConstructorSettings,
        addresses: employerAddresses,
    });

    /**
     * если среди невалидных виджетов есть текст с превышением длины - запрос не будет отправлен,
     * и первый найденный компонент (среди отсортированных в порядке расположения на странице) сделает скролл на себя
     */
    const sortedErrorWidgets = sortWidgets([...errorWidgets], employerConstructor.widgetsOrder);
    const constructorHasInvalidTextWidget = sortedErrorWidgets.some(({ widget, error }) => {
        const isInvalidTextWidget = widget.type === 'TEXT' && error === 'INVALID_LENGTH';
        if (isInvalidTextWidget) {
            dispatch(
                employerConstructorModifyWidget({
                    id: widget.id,
                    invalidOnSave: true,
                    invalid: true,
                })
            );
        }
        return isInvalidTextWidget;
    });
    if (constructorHasInvalidTextWidget) {
        return null;
    }

    // сортируем widgets по widgetsOrder, выбираем оттуда только валидные виджеты
    const sortedWidgets = sortWidgets([...employerConstructor.widgets], employerConstructor.widgetsOrder);
    const validWidgets = getOnlyValidWidgets(sortedWidgets, errorWidgets);

    const requestData = {
        headerBackgroundPictureId: employerConstructor.pictureId,
        sidebarColors: employerConstructor.sidebarColors,
        widgets: validWidgets,
    };

    dispatch(setConstructorStatus(Status.Fetching));
    return fetcher.post('/employer/constructor/save_template', requestData).then(
        () => {
            const isRussia = getState().countryId === CountryId.Russia;
            openConnectReviewsModal(isRussia, employerConstructor, validWidgets);

            dispatch([setConstructorStatus(Status.Success), employerConstructorSetSaved()]);
            addNotification(employerConstructorSaveTemplateOk);
        },
        (error) => {
            dispatch(setConstructorStatus(Status.Fail));
            // TODO: почему не сохранилось?
            if (error.response.status === FORBIDDEN) {
                addNotification(employerConstructorEditForbiddenError);
            } else {
                addNotification(employerConstructorSaveTemplateError);
            }
        }
    );
};

export default saveConstructorTemplate;
