import { AxiosError } from 'axios';

import { CriteriaKey } from 'lux/models/search/searchCriteria.types';
import fetcher from 'src/utils/fetcher';

export const STATUS = {
    SUCCESS: 'Success',
    CONFLICT: 'Conflict',
    ERROR: 'Error',
};

interface AutosearchSave {
    employerId: number;
    email?: string;
    autosearchName?: string;
}

const autosearchSave = ({ employerId, email, autosearchName }: AutosearchSave): Promise<{ state: string }> => {
    const url = '/vacancysavedsearch/save';

    return fetcher
        .postFormData(
            url,
            { email, autosearchName },
            {
                params: {
                    [CriteriaKey.EmployerId]: [employerId],
                },
            }
        )
        .then(
            ({ data }) => {
                return data;
            },
            (error: AxiosError) => {
                throw new Error(error.response?.status === 409 ? STATUS.CONFLICT : STATUS.ERROR);
            }
        );
};

export default autosearchSave;
